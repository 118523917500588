import { render, staticRenderFns } from "./fras_libres_M.vue?vue&type=template&id=2794c01a&scoped=true"
import script from "./fras_libres_M.vue?vue&type=script&lang=js"
export * from "./fras_libres_M.vue?vue&type=script&lang=js"
import style0 from "./fras_libres_M.vue?vue&type=style&index=0&id=2794c01a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2794c01a",
  null
  
)

export default component.exports