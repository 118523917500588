/* eslint-disable */
<!-- Mto Presupuestos Funerarias Sucursales -->

<template>
  <div class="fras_libres_M" v-if="schema">
    <base_Header
      v-if="Entorno.header.header"
      :Entorno="Entorno.header"
      @onEvent="event_Header"
    >
    </base_Header>

    <v-sheet class="contenedor" :elevation="4">      
    
      <!-- botoneras -->
      <!-- <div class="conflex" style="width:790px;margin-bottom:5px"> -->
      <div class="conflex" style="width: 1100px;margin-bottom: 5px">
        <!-- btra Mto -->

        <btramto
          class="conflex"
          style="width: 230px"
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="m"
          :estado="estado"
          @onEvent="event_btra_Mto"
        >
        </btramto>

        <!-- extra -->
        <div class="conflex" style="width:60px; margin-left: 50px">
          <btraextra
            class="conflex"
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="standard"
            @onEvent="event_btra_Extra"
          >
          </btraextra>
        </div>

        <!-- botones comunes -->
        <div
          class="conflex"
          style="
            width: 150px;
            justify-content: space-between;
            margin-left: 10px;
          "
        >
          <v-btn
            v-bind="$cfe.default.btra.std"
            @click="pdf()"
          >
            <v-icon> {{ $cfe.btn.pdf.icon }} </v-icon>
          </v-btn>

          <!-- Envio Facturas -->
          <fras_Exp_email
            :padre="miStPadre.name"
            :id="accionRow.id"
            :accionRow="accionRow"
            :show="estado!='nuevo'">            
          </fras_Exp_email>

          <v-btn
            fab
            depressed
            x-small
            color="blue-grey lighten-4"
            @click="copy()"
          >
            copy
          </v-btn>

          <v-btn
            fab
            depressed
            x-small
            color="blue-grey lighten-4"
            :disabled="!is_edit"
            @click="pega()"
          >
            pega
          </v-btn>
        </div>
        <!-- fin botonera comun -->
      </div>

      <!-- fila 1 -->
      <div class="conflex" style="width: 1180px;">
        <!-- fila 1 columna 1 -->
        <div class="columna" style="flex: 0 1 750px">
          <div class="conflex" style="justify-content: space-between">
            <div class="cab">DATOS PRESUPUESTO</div>
            <div style="color: steelblue; padding-right: 20px">
              {{ accionRow.id }}
            </div>
          </div>
          <v-sheet v-bind="$cfg.styles.marco" style="width: 750px">
            <div class="conflex">
              <compMultiple
                style="flex: 1 1"
                :ctrl="schema.ctrls.cta_id"
                :schema="schema"
                :disabled="!is_edit"
              >
              </compMultiple>

              <div
                v-if="schema.ctrls.pdi.value == '1'"
                style="font-weight: bold; font-size: 1.5rem; color: red"
              >
                PDI
              </div>
            </div>
            <!-- fin linea -->
            <vlinput
              label="DESCRIP"
              v-model="schema.ctrls.descrip.value"
              :disabled="!is_edit"
            >
            </vlinput>
            <div class="conflex" style="flex: 1 1 750px">
              <v-select
                style="text-align: center; flex: 0 1 100px"
                v-model="schema.ctrls.rt.value"
                v-bind="$select"
                label="Tipo I."
                :items="this.$store.state.datos_iniciales.rTribut"
                :disabled="!is_edit"
                item-value="id"
                item-text="name"
                @change="changeRt"
              ></v-select>

              <v-select
                style="flex: 0 1 100px"
                v-model="schema.ctrls.iva_general.value"
                v-bind="$select"
                label="% Gral"
                :items="get_ivas"
                :disabled="!is_edit"
                item-value="value"
              ></v-select>

              <pres_sumas style="flex: 1" :pdat="schema.ctrls" />
            </div>

            <v-textarea
              v-bind="$textarea"
              v-model="schema.ctrls.obs_fra.value"
              :label="schema.ctrls.obs_fra.label"
              no-resize
              rows="1"
              :disabled="!is_edit"
            >
            </v-textarea>
          </v-sheet>
        </div>

        <!-- fila 1 columna 2 -->
        <div class="columna" style="width: 240px">
          <div class="conflex" style="align-items: baseline">
            <div class="cab" style="width: 80px; margin-right: 5px">
              OperaciC3n
            </div>
            <div :style="tipo_ope.style + ';padding:0'">
              {{ tipo_ope.text }}
            </div>
          </div>
          <v-sheet v-bind="$cfg.styles.marco">
            <vlinput
              label="N.FRA "
              stilot="font-weight:bold;color:steelblue"
              stilol="flex:0 60px"
              v-model="schema.ctrls.fra_doc.value"
              :disabled=true
            >
            </vlinput>

            <vlinput
              v-model="schema.ctrls.fra_fh.value"
              label="FECHA"
              type="date"
              stilol="flex:0 60px"
              :disabled=true
            >
            </vlinput>
            <div class="conflex" style="justify-content: space-between">
              <select
                class="select"
                :disabled="!is_edit || rol != 'gestion'"
                v-model="schema.ctrls.rel_tp.value"
              >
                <option
                  v-for="item in itemsTipoCargo"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div v-if="schema.ctrls.rel_tp.value > 0">
              <vlinput
                label="D/FR."
                stilol="flex:0 60px"
                :disabled="!is_edit || rol != 'gestion'"
                v-model="schema.ctrls.relacion.value"
              >
              </vlinput>
            </div>
          </v-sheet>

          <v-select
            style="text-align: center; flex: 0 1 100px"
            v-model="schema.ctrls.ctp.value"
            v-bind="$select"
            label="Tipo"
            :items="this.$store.state.datos_iniciales.tipo_libres"
            :disabled="!is_edit"
            item-value="id"
            item-text="name">            
          </v-select>

          <!-- fin columna 2 -->
        </div>

        <div class="columna">
          <div class="conflex">
            <v-icon
              large
              :color="
                viewBloqueada[Number(schema.ctrls.bloqueada.value) || 0].color
              "
              >>
              {{
                viewBloqueada[Number(schema.ctrls.bloqueada.value) || 0].icon
              }}
            </v-icon>

            <v-icon
              large
              :color="
                viewAceptada[Number(schema.ctrls.aceptada.value) || 0].color
              "
            >
              {{ viewAceptada[Number(schema.ctrls.aceptada.value) || 0].icon }}
            </v-icon>
          </div>

          <!-- fin columna 3 -->
        </div>
      </div>

      <!-- fila 2 -->
      <div class="fila2 conflex">
        <!-- fila2 columna 1-->
        <div class="columna" style="padding-right: 8px">
          <exp_pres_detalle
            :padre="stName"
            :id="$store.state[stName].record.id"
            :disparoFinder="disparoFinder"
            @onEvent="event_btra_Mto"
            @onEventOrden="eventOrden"
          >
            >
          </exp_pres_detalle>
        </div>
        <!-- fin fila2 columna 1 -->

        <!-- fila2 columna 2 -->
        <div v-if="!is_mxEdit" class="columna" style="width: 420px">
          <pres_iva_desglose :pdat="schema.ctrls" :is_edit="is_edit">
          </pres_iva_desglose>

          <div class="cab">Factura
            <span style="color: gray; padding-left: 25px">{{ accionRow.id }}</span>
            </div> 
            <v-sheet v-bind="$cfg.styles.marco">
              <div>                                
                <vllabel
                  style="flex: 0 0 100%"
                  label="Emitida"
                  :txt="record.fra_emision | fechahr">              
                </vllabel>

                <vllabel
                  style="flex: 0 0 100%"            
                  label="Ope"  
                  :txt="record.fra_emision_usu">              
                </vllabel>            

                <vllabel
                  style="flex: 0 0 100%"
                  label="Conta"
                  :txt="record.fra_fhcon | fecha">            
                </vllabel>
              
                <vllabel
                  style="flex: 0 0 100%"
                  label="Contabilizada"
                  :txt="record.fra_conta_fh | fechahr">              
                </vllabel>
                
                <vllabel 
                  style="flex: 0 0 100%"  
                  label="Ope"           
                  :txt="record.fra_conta_usu">              
                </vllabel>           
               
                <vllabel
                  style="flex: 0 0 100%" 
                  label="Asiento"
                  :txt="record.fra_conta_asi">              
                </vllabel>

                <vllabel
                  style="flex: 0 0 100%" 
                  label="Vencimiento"
                  :txt="record.fra_vto | fecha">                   
                </vllabel>

                <vllabel
                  label="Pagada"
                  :txt="record.fra_pago_fh | fecha">              
                </vllabel> 

                <vllabel
                  label="Importe"
                  :txt="record.fra_pago">              
                </vllabel>

                <v-btn fab depressed x-small color="blue-grey lighten-1"
                  :disabled="is_edit"
                  title='Pagos Factura'
                  @click="pagoFras">
                  <div style="color:white;font-weight:bold;font-size:1rem"> P </div>
                  <sub style="color:blue;font-size:1rem;"> F</sub>
                </v-btn>
              </div>
            </v-sheet>

          <!-- <div v-if="schema.ctrls.tipo_ope.value == 'V'">
            <div class="cab" style="width: 125px">OTROS CONCEPTOS</div>
            <v-sheet v-bind="$cfg.styles.marco">
  
              <div class="conflex">
                <v-input_n
                  style="width: 50%"
                  @blur="calculo()"
                  v-bind="$input"
                  v-model="schema.ctrls.irpf.value"
                  label="IRPF"
                  :disabled="!is_edit"
                ></v-input_n>

                <v-input_n
                  style="width: 50%"
                  @blur="calculo()"
                  v-bind="$input"
                  v-model="schema.ctrls.irpfpor.value"
                  simbolo="%"
                  label="%"
                  :disabled="!is_edit"
                ></v-input_n>
              </div>
            </v-sheet>
          </div> -->


          
          <!-- fin if compras/ventas -->
        </div>
      </div>
      <!-- fin bloque -->
    </v-sheet>

    <!-- Ventana Pagos Factura -->
      <v-dialog 
        v-model="modalPag" 
        content-class="modal"
        persistent>
          <div class="centrado">
            <fras_Pagos
              
              :accionRow="record"
              :disparoFinder="disparo_pag"
              @refrescar="refrescaFras($event)"
              @onEvent="$event.accion==6 || $event.accion=='cerrar' ? modalPag= false : ''">  

            </fras_Pagos>                
          </div>
      </v-dialog>       

  </div>
</template>

<script>
import { mixinMto } from "@/mixins/mixinMto.js";
import { mixinMD } from "@/mixins/mixinMD_maub.js";
// import { mixinCalculos } from "@/mixins/mixinCalculos.js";
import { funes_pres_calculo } from "@/mixins/funes_pres_calculo.js";
// import { calculo_claves } from "@/mixins/calculo_claves.js";
import { mixinPresPack } from "@/mixins/mixinPresPack.js";
import plugs from "@/common/general_plugs";
const base_Header = () => plugs.groute("base_Header.vue", "base");
const btramto = () => plugs.groute("btramto_new.vue", "comp");
const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
// const compfecha = () => plugs.groute("compfecha.vue", "comp");
// const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
const exp_pres_detalle = () => plugs.groute("fras_libres_detalle.vue", "comp");
// const pres_claves = () => plugs.groute("pres_claves.vue", "comp");
const pres_sumas = () => plugs.groute("pres_sumas.vue", "comp");
const pres_iva_desglose = () => plugs.groute("pres_iva_desglose.vue", "comp");
const compMultiple = () => plugs.groute("compMultiple.vue", "comp");
const fras_Exp_email = () => plugs.groute("fras_Exp_email.vue", "comp");
// const funes_pres_import_F = () => plugs.groute("funes_pres_import_F.vue", "comp");
// const exp_Docs = () => plugs.groute("exp_Docs.vue", "comp");
// const info_cta_view = () => plugs.groute("info_cta_view.vue", "comp");
// const exp_Comunicados = () => plugs.groute("exp_Comunicados.vue", "comp");

// const pres_total_cia = () => plugs.groute("pres_total_cia.vue", "comp");
// const pres_total_compra = () => plugs.groute("pres_total_compra.vue", "comp");

const fras_Pagos = () => plugs.groute("fras_Pagos.vue", "comp");



export default {
  // mixins: [mixinMto, mixinMD, funes_pres_calculo, calculo_claves, mixinPresPack],
  mixins: [mixinMto, mixinMD, funes_pres_calculo, mixinPresPack],
  components: {
    base_Header,
    btramto,
    btraextra,
    // compfecha,
    // ctrlfinder,
    exp_pres_detalle,
    // pres_claves,
    pres_sumas,
    pres_iva_desglose,
    compMultiple,
    fras_Exp_email,
    fras_Pagos
    // funes_pres_import_F,
    // exp_Docs,
    // info_cta_view
    // exp_Comunicados
    // pres_total_compra,
  },

  props: {
    padre: { type: String, default: "" },
    accion: { type: [Number, String], default: "" },
    accionRow: { type: Object, default: () => {} },
    disparo: { type: Boolean, default: false },
    componenteTipo: { type: String, default: "M" },
    Entorno: {
      type: Object,
      default: function () {
        return JSON.parse(JSON.stringify(this.$cfg.base.M));
      },
    },
    datos_complementarios:{},

  },

  data() {
    return {
      schema: null,
      api: "exp_pres_M",
      stName: "stLibresM",
      stNameHijo:  "stLibresD",
      mtos_auxiliares: [
          { store:'stLibresMX', msg:'Tiene una linea de producto en edición. Guarde o Cancele antes de continuar'},
        ],
  
      // Pendiente: ver donde ponemos campo_relacionado
      campo_relacionado: "exp_id",
      tipo_detalle: "fras",
      disparoFinder: false,

      // Lo quito de propiedades y lo pongo en data para solo facturas libres
      auxRecord:{record:{id:0}},

      //
      // nuevo presupuesto
      sw_nuevoInterno:false,
      datos_complementarios_internos:{},

      //producto: null, // pendiente: no creo que se use
      fila_copiada: null,
      pservicios: null,
      lineas_actualizadas: false,

      records_detalle: null,
      schema_detalle: null,
      itemsTipo_ope:[
        { tipo_ope:"", text:"ERROR TIPO PRESSUPUESTO", style:"font-weight:bold;color:steelblue"},
        { tipo_ope:"C", text:"COMPRA",  style:"font-weight:bold;font-size:.8rem;color:CornflowerBlue"},
        { tipo_ope:"V", text:"VENTA",   style:"font-weight:bold;font-size:.8rem;color:rgb(255, 128, 0);"},
        { tipo_ope:"SUP", text:"SUPLIDO",   style:"font-weight:bold;font-size:.8rem;color:red;"},
        { tipo_ope:"W", text:"VENTA TRAMITACIÓN",   style:"font-weight:bold;font-size:.8;color:rgb(255, 128, 0);"}
        ],
      itemsTipoCargo: [
        { value: 0, text: "Seleccione Cargo/ABono"},
        { value: 1, text: "PARCIAL" },
        { value: 2, text: "NULO" },
        { value: 3, text: "TOTAL" }
      ],
      // Productos con IVA 10% FLORES
       iva10:[12,21,52],
      //
      itemsCias: [
        { id: "83", name: "MAPFRE" },
        { id: "50165", name: "KUTXABANK" },
      ],

      // itemsPrestacion: [],
      // noduplicar_tipos:[10, 30, 18, 38, 36, 16, 17, 37],
      // municipio_required: [10, 11, 30, 31, 18, 38, 36, 16, 17, 37],
      // apd_tipos: [10, 11, 30, 31, 18, 38],
      apd_cias: [50165, 50508],
      itemsPlantilla:[{id:'4', name:'Servicio IncineraciC3n'},
                     {id:'3', name:'Servicio Inhumacion'},
                     {id:'5', name:'Servicio traslado'},
                     {id:'6', name:'Servicio recepcion/terminaciC3n'}
      ],
      plantillaValue:'0',
      plantillaDisabled:true,
      viewAceptada:[
        {icon:"mdi-check-bold", color:"grey"},
        {icon:"mdi-check-bold", color:"green"},
        {icon:"mdi-close",     color:"red"}
      ],
       viewBloqueada:[
        {icon:"mdi-lock-open", color:"green"},
        {icon:"mdi-lock",      color:"red"}
      ],

      // para lanzar exp_m_pressup
      disparo_pag:false,
      modalPag:false,


    };
  },

  methods: {
    // inicializo hijo antes cargar datos
    before_ini_component() {
      if (!this.$store.state[this.stName]) return;
      if (!this.$store.state[this.stName].hijo) return
      this.$store.commit(this.stName + '/data2State', { prop:'hijo', value:this.stNameHijo });
    },

    eventOrden(evt){

      let item={};
      if (evt.item) item=evt.item;
      if (item=={}) return;
      const hijo=this.$store.state[this.stName].hijo
      let arrayRecords=JSON.parse(JSON.stringify(this.$store.state[hijo].records));
      //encuentro el index del item en la array
      let index = arrayRecords.findIndex(e => e.id == item.id);
      //

      if (evt.accion=='down'){
          if (index !== -1 && index < arrayRecords.length - 1) {
                item = arrayRecords[index];
                arrayRecords[index] = arrayRecords[index + 1];
                arrayRecords[index + 1] = item;
          }
      }
      if (evt.accion=='up'){
        if (index > 0) {
          item= arrayRecords[index];
          arrayRecords[index] = arrayRecords[index - 1];
          arrayRecords[index - 1] = item;
        }
      }
        arrayRecords=arrayRecords.map((e,index) => {e.orden=index; return e});
        this.$store.commit('data2State', { modulo:hijo, prop:'records', value:arrayRecords});
        this.detalle_modificado(this.$store.state[hijo]);
    },

    ini_data() {
      console.log("DEV " + this.api + " ********************** (ini_data)");

      // guardo del store en variables locales
      this.schema = this.$store.state[this.stName].schema;

      // configuro Entorno
      this.Entorno.header.titulo = "Presupuestos/Facturas Libres";
      this.Entorno.header.style += ";min-width:60rem";
      //this.Entorno_presup_detalle_F=JSON.parse(JSON.stringify(this.$cfg.base.D));
      // btra mantenimiento
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));
        this.Entorno.btra.footer.extra = [

          /* { accion: "acepto", icono: "mdi-check-bold", show: 1 },
          { accion: "bloqueo", icono: "mdi-lock", show: 1 },
          { accion: "pasoVenta", texto:"V", view:false,disabled:true,styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1 },
 */          { accion: "pasoFactura", texto:"F", view:false,disabled:true,styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1 },
          /* { accion: "pasoTramitacion", texto:"TR", view:false,disabled:true,styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1 },
 */

        ];
      // obtengo items adicionales
      // this.itemsPrestacion = this.$store.state.datos_iniciales.tipo_presup;
      // this.itemsAno = this.getItemsAno();
    },


  ver_params() {
      return {
        tipo: "fnc",
        accion: "presupuestos",
        fn_args: {
          tipo: this.tipo_detalle,
          accion: "get",
          id: this.accionRow.id,
        },
      };
  },

// CIERRES DE EXPEDIENTES-----------------------------------


// BORRADO -------------------------------------------------
  borrar() {
    if  (this.schema.ctrls.aceptada.value==1 ||
           this.schema.ctrls.bloqueada.value==1 ||
           this.schema.ctrls.fra_doc.value>'') {

            // console.log("aceptada",this.schema.ctrls.aceptada.value,
            //            "bloqueada",this.schema.ctrls.bloqueada.value,
            //            "facturada",this.schema.ctrls.fra_doc.value)
            //

           this.$root.$alert.open("DENEGADO ELIMINAR (presupuesto aceptado o bloqueado o Facturado", "error");
           return
           }
      this.borrar_pregunta()
    },
    borrar_pregunta(){
     this.$root.$alert.open("B?Confirma Eliminar?:","confirm")
         .then((r)=>this.borrar_respuesta(r));
     },

    borrar_respuesta(r){
      if (r) this.borrar_registro(this.accionRow.id);
    },
//fin borrado--------------------------------

   botoneras_set(){
     this.btnSet(1,{view:true, disabled:true})
    // // this.$store.state.G.APLICA.VERSION (1:gestion, 2:apd, 3:consulta)
    // // this.$store.state.G.G.USUARIO (usu)
    // //                  .ROL ("gestion","apds","jz","consultas")
    // //                   .ZONA (0)
      const COMPRA=this.schema.ctrls.tipo_ope.value=="C";
      const VENTA=this.schema.ctrls.tipo_ope.value=="V";
      let TRAMITACION=this.schema.ctrls.tipo_ope.value=="W";

      /* let ACEPTADA=this.schema.ctrls.aceptada.value=='1';
      let FACTURADA=this.schema.ctrls.fra_doc.value>''; */
      let CONTABILIZADA=this.schema.ctrls.fra_conta_asi>'';

      let GESTION=this.app==1;

      // let APDS=this.app==2
    //----------------------------------------
      // console.log("cierre=",this.expte.record.chkconf24,this.expte.record.chkconfadmon,this.expte.record.chkconfcierre)
      // let CIERRE=(APDS && this.expte.record.chkconf24==1);
      // if  (!CIERRE) CIERRE=this.expte.record.chkconfadmon==1;
      // if  (!CIERRE) CIERRE=this.expte.record.chkconfcierre==1;
      //  if (CIERRE){
      //    this.botones_cierre()
      //    return
      //  }

      //-----------------------------------------------
      // habilitar edicion
        this.btnSet(2,{view:true, disabled:false});
        this.btnSet(3,{view:true, disabled:false});

      // NO EDICION - VER ----------------------------
      if (!this.is_edit){
          this.btnSet(2, { view:true, disabled:this.record.fra_conta_asi> 0 })
          this.btnSet(3, { view:true, disabled:this.record.fra_conta_asi> 0 })
          this.btnSet("pasoFactura", { view:true, disabled:this.record.fra_conta_asi> 0 })
          /* this.btnSet("pasoTramitacion",{view:GESTION, disabled:true})
          this.btnSet("pasoVenta", {view:GESTION, disabled:true})
          this.btnSet("acepto",{view:true, disabled:false})
          this.btnSet("bloqueo",{view:true, disabled:false})
          this.btnSet("imp_prestos",{view:COMPRA, disabled:true}) */
          this.plantillaDisabled=true;


          // COMPRA
          /* if (GESTION && COMPRA && ACEPTADA) {
            this.btnSet("pasoVenta",{view:true, disabled:false})
            this.btnSet("pasoTramitacion",{view:GESTION, disabled:false})
          } */

          // VENTA
          /* if (GESTION && (VENTA || TRAMITACION)  && ACEPTADA && !FACTURADA){
            this.btnSet("pasoFactura",{view:true, disabled:false})
          } */

          if (GESTION && (VENTA || TRAMITACION || COMPRA) && CONTABILIZADA){
              this.botones_cierre()
          }
        }


       //-----------------------------------------------------------------
      // EDICION
     if (this.is_edit) {
        this.btnSet("pasoFactura",{ disabled:true });        
     }
   },
    botones_cierre(){
        this.btnSet(1,{view:true, disabled:true})
        this.btnSet(2,{view:true, disabled:true})
        this.btnSet(3,{view:true, disabled:true})
        this.btnSet("pasoFactura",{view:false, disabled:true})
        /* this.btnSet("pasoVenta", {view:false, disabled:true})
        this.btnSet("pasoTramitacion", {view:false, disabled:true})
        this.btnSet("acepto",{view:false, disabled:true})
        this.btnSet("bloqueo",{view:false, disabled:true}) */
        this.plantillaDisabled=true;
    },
    detalle_cargado() {
      //alert("detalle_cargado exp_pres_M")
      // this.calculo_capitales();
    },



   // pendiente: arreglado asi aqui  porque pasa dos veces por record cargado
   // y no puedo poner sw_nuevoInterno a false en records
   // probar a poner en nuevo todo lo de record_cargado
    cancelar_fin(){
       this.sw_nuevoInterno=false;
    },
    record_cargado() {
  
      // reseteo variables auxiliares de procesos

      //
      if (this.estado!="nuevo"){

        this.sw_nuevoInterno=false;
        this.botoneras_set()
         if (!this.schema.ctrls.rt.value) {
             this.ini_ivas()
         }
         return
      }


      // valores por defecto
    this.schema.ctrls.aceptada.value=0;
    this.schema.ctrls.bloqueada.value=0;

    let complemento=this.sw_nuevoInterno?this.datos_complementarios_internos:this.datos_complementarios;
    //console.log("paso complememto", complemento);
    //console.log("paso complememto interno", this.datos_complementarios_internos);
    this.nuevo_complemento(complemento)
   },

   //aseguramos que si suplido, pdi =1
  guardar_before() {
    
     //console.log("###guardar_before",this.schema.ctrls.suplido.value,this.schema.ctrls.pdi.value)
      if (this.schema.ctrls.suplido.value=='1') this.schema.ctrls.pdi.value='1';
      this.schema.ctrls.aceptada.value='1';
      return true;
   },


  async nuevo_complemento(complemento){
       //console.log("paso nuevo_complememto", complemento);
       this.schema.ctrls.rel_tp.value="0";
       // DATOS_COMPLEMENTARIOS: tipo_op, rt, iva_gral, lineas(si hay)

       // Tipo compra/venta/suplido
      // cab=datos cabecera
      // lins=array de detalle

       let cab=complemento.cab;
       let lins=complemento.lins;
      //   console.log("complememto 3", complemento);
      //  console.log("lineas lins:",lins)
      //  console.log("schema",this.schema.ctrls)
      //  console.log("cab",cab)
       this.schema.ctrls.tipo_ope.value=cab.tipo_ope;
       this.schema.ctrls.suplido.value=cab.suplido;

       // cuenta pongo valores de componente manualmente
       if (cab.tipo_cta) {
         this.schema.ctrls.tipo_cta.value=cab.tipo_cta
         if (cab.cta_id){
           this.schema.ctrls.cta_id.value=cab.cta_id;
         }
        let tmpRecord={tipo_cta:cab.tipo_cta, cta_id:cab.cta_id}
        let x=await this.pick_multiple().set({ctrl:this.schema.ctrls.cta_id,record:tmpRecord})

        this.schema.ctrls.cta_id.value=cab.cta_id;
        this.schema.ctrls.tipo_cta.value=cab.tipo_cta;

        if (x!==undefined){
          if (x.pdi!==undefined)
          this.schema.ctrls.pdi.value=x.pdi
        }
       }

       this.botoneras_set()
      // IVA si lo ha importado lo asigna a ctrls, si no, analiza lineas para aplicar uno
       if(cab.rt && cab.iva_general){
            this.schema.ctrls.rt.value=String(cab.rt)
            this.schema.ctrls.iva_general.value=String(cab.iva_general)
       } else {
         // inspecciona las lineas y averigua iva
         this.ini_ivas(this.getLineasRt(lins))
       }
       // para tramitacion
       if (cab.relacion) this.schema.ctrls.relacion.value=cab.relacion;
       if (cab.tmt1)    this.schema.ctrls.tmt1.value=cab.tmt1;

       //console.log("iva final:",this.schema.ctrls.rt.value, this.schema.ctrls.iva_general.value)

       //pasar datos de array a records de detalle

       if (cab.tipo_ope=="V" && Array.isArray(lins) && lins.length){
         lins=this.pasoVentaRecords(lins,cab.suplido)
       }
       this.lineas2records(lins)

       return
    },
   lineas2records(lins){
       if (Array.isArray(lins) && lins.length){
          this.$store.commit(this.$store.state[this.stName].hijo + "/data2State", {prop:"records", value:[...lins]});
       }
       this.calculo();
     },
   pasoVentaRecords(lineas,suplido=0){

       if (Array.isArray(lineas) && lineas.length)
      suplido=Number(suplido); // directo de una factura de suplido
      //let iva=Number(this.schema.ctrls.iva_general.value);

      // en ventas siempre el 21 o el 3  por eso tomo el primer elemento del rC)gimen tributario
      // por si han cambiado en la cabecera el iva_general y han puesto otro porcentaje
      let iva_general=this.$store.state.datos_iniciales.rTribut[this.schema.ctrls.rt.value].tipos[0].value;

      let rt=this.rTributName;
      let iva10=[12,21,52];
      // let iva10=this.$store.state.datos_iniciales.iva10;
      //console.log("rt,iva", rt, iva10)

      //
      // map
      let recordsVenta=lineas.map(function(item){
        //console.log("pasoVentaRecords lineas ", item.imp)
          //if (item.sup=="S"){
          //    item.sup="N"
              //sustituyo maub
             // item.iva=iva
          //    item.iva=iva_general
         // }
          item.sup="N";
          item.iva=iva_general;

          // las flores al 10%
          if (rt=="IVA" && iva10.includes(Number(item.prod_id))){
              item.iva="10.0"
          }
          // exception tipo_op SUPLIDO
          // viene de una fusion / factura de suplidos (suplido==1)
          let lineaSuplido=Number(item.suplido || 0);
          if (lineaSuplido==1 || suplido==1) {
            item.sup="S"
            item.iva="0.0"
          }
          return item;
      })
      // fin map
      // console.log("Venta:", recordsVenta)
       return recordsVenta;
    },
   // ACCIONES QUE SE REALIZAN CUANDO HAY UNA MODIFICACION EN EL DETALLE
    detalle_modificado() {
      this.calculo();
    },
    calculo(){
      let records= this.miStHijo!= null? this.miStHijo.records : [];

      this.pres_calculo(
      records,
      this.schema.ctrls,
      this.schema.ctrls.tipo_ope.value);
      // this.calculo_capitales();
    },

    getLineasRt(lineas){
       //console.log("lineas analizando:",lineas)
        if (lineas.length==0) return "0";
        // Segun iva de lineas de detalle devuelve el  indice de rTtribut al que pertenece
        let a=[0,0,0,0]
        let tipos=this.$store.state.datos_iniciales.rTribut.map(i=>i.tipos.map((j)=>Number(j.value)))
        lineas.filter(c=>tipos.filter((t,index)=>{if (t.includes(c.iva)) a[index]++}))
        return  a.indexOf(Math.max(...a)).toString();
    },
    ini_ivas(rt="0"){
      // console.log("ini_ivas1: ",this.schema.ctrls.rt.value)
      //  console.log("ini_ivas2: ",this.schema.ctrls.iva_general.value)
      this.schema.ctrls.rt.value = rt;
      this.schema.ctrls.iva_general.value = this.$store.state.datos_iniciales.rTribut[this.schema.ctrls.rt.value].tipos[0].value;
     // console.log("ini_ivas: ",this.schema.ctrls.rt.value,this.schema.ctrls.iva_general.value)
    },
    //
    async get_schema_directo() {
      // Pendiente comentar cuando se utiliza esta funcion

      // ejecuto API
      let args = {
        tipo: "M",
        api: "exp_presLin_M",
        accion: "get_schema",
        fn_args: null,
      }

    //  console.log("----------------AWAIT SCHEMA DIRECTO ", args);
      let apiResult = JSON.parse(
        await this.$store.dispatch("ajaxRequest", { args: args })
      );
    //  console.log("----------------AWAIT SCHEMA DIRECTO FIN", apiResult);

      return apiResult;
    },

    async validate_particular() {
   
      let err='';
      if (Number(this.schema.ctrls.imp.value<0)) {
                     //
          if (Number(this.schema.ctrls.rel_tp.value)==0){
            err+="* DEBES MARCAR TIPO CARGO O ABONO (C/A)"
          }
         //
         if (this.schema.ctrls.relacion.value==''){
             err+="* DEBES DE INTRODUCIR FACTURA RELACIONADA (C/A)   "
         }
         //
         if (err>"") {
            this.$root.$alert.open(err, "error")
            return false
         }
     
     }
      return true
  },

  
   async pasoFactura(){
      this.$root.$alert.open("¿ Pasar a <b>FACTURA VENTA Y CONTABILIZAR</b> ?<br>" +
          "<br>Se genera Numero de Factura y Fecha.<br>Despues de Aceptar se Emite Factura en PDF y se contabiliza.<br><br>"
          ,"confirm")
        .then((r)=>this.pasoFactura_respuesta(r)
      );
   },
   async pasoFactura_respuesta(r){
     if (!r) return;
      let args={
        tipo:"fnc",accion: "facturas",fn_args:{accion:"factura_numero", id: this.record.id, opc: 1 }
      };      
      let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });

      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      this.btra_Mto({accion:0})
      this.pdf();

    },
  
    //pagos  
    pagoFras() {
      this.disparo_pag= !this.disparo_pag;
      this.modalPag= true;       
    },
    async refrescaFras(e) {
      console.log('refrescarFras',e);
      /*await this.refrescar();
        
      let R=this.miSt.records;
      let S=R.filter((item)=>{ return item.id==e.id; });
      //this.accionRow=S[0];
      this.selected=S;

      console.log("**refrescaFras", e, R, S, this.selected, this.accionRow.id);   
      //alert(e.id + ' - ' + S[0].fra_doc);*/

      this.ini_component();

    },



// BOTONERA COMUN--------------------------------------------------------------------------
  async pdf() {
    if (this.record.id==0) return;
    let args = { tipo: 'fnc', accion: 'expediente', fn_args: { accion: "factura", id: this.record.id, opc: 1 }};

    let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });

    if (apiResult.sql.error) {
      this.$root.$alert.open(apiResult.sql.msg, 'error');
      return;
    }

    // abro documento
    window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");

  },


  copy(){
    const hijo=this.$store.state[this.stName].hijo
    const records=JSON.parse(JSON.stringify(this.$store.state[hijo].records));
    if (records.length==0){
      this.$root.$alert.open('No hay datos para copiar', 'error')
      return
    }
    this.$store.commit('data2State', { modulo:'datos_iniciales', prop:'recordsCopy', value:records});
    this.$root.$alert.open('Datos copiados', 'info')
  },
  pega() {

    const hijo=this.$store.state[this.stName].hijo
    const records=JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.recordsCopy));

    if (records.length==0){
      this.$root.$alert.open('No hay datos para pegar', 'error')
      return
    }
    if (records.length>0 && this.is_edit) {
        this.$store.commit('data2State', { modulo:hijo, prop:'records', value:records});
        this.calculo()
        this.$root.$alert.open('Datos pegados', 'info')
    }
  },


  //
  changeRt(val) {
    let ivaStr=this.$store.state.datos_iniciales.rTribut[val].tipos[0].value;
    this.schema.ctrls.iva_general.value= ivaStr;
    let hijo = this.$store.state[this.stName].hijo;
    let records=JSON.parse(JSON.stringify(this.$store.state[hijo].records));
    // const records=this.MD.records;
 
    records.forEach(item => {
                        if(item.sup=='N') item.iva=ivaStr;
                        if (this.rTributName=="IVA" && this.iva10.includes(Number(item.prod_id))){
                             item.iva="10.0"
                         }
    });
    this.$store.commit('data2State', { modulo:hijo, prop:'records', value:records});
    this.calculo();
  },


//------------------------------------------------------------------------------------------------
//
// PENDIENTE: meter en mixinctrls
  conDato(i) {
      if (i == "0" || i == "" || typeof i === "undefined" || i == null) return false;
      return true;
    },
  },

  computed: {
    expte(){
    
        return this.auxRecord;
 
    },
    tipo_ope(){
      let tipo=this.schema.ctrls.tipo_ope.value;
      if (this.schema.ctrls.suplido.value==1) tipo="SUP";
      let obj=this.itemsTipo_ope.find(item =>item.tipo_ope==tipo);
      return obj?obj:this.itemsTipo_ope[0];
    },


    is_mxEdit() {
  

      var hijo = this.$store.state[this.stName].hijo;
      if (!hijo) return false;
  
      var nieto = this.$store.state[hijo].hijo;
    
      if (!nieto) return false;
      return this.$store.state[nieto].estado === "editar" ||
        this.$store.state[nieto].estado === "nuevo"
        ? true
        : false;
    },

    get_ivas() {
      if (!this.$store.state.datos_iniciales) return [];
      if (this.schema.ctrls.rt.value) {
        return this.$store.state.datos_iniciales.rTribut[Number(this.schema.ctrls.rt.value)].tipos;
      }

       return[];
    },


    rTributName(){
     if (this.schema.ctrls.rt.value!='') {
       return this.$store.state.datos_iniciales.rTribut[Number(this.schema.ctrls.rt.value)].name
     }
     return ''
    },
     miStAbuelo() {
      if (!this.miStPadre) return null;
      if (!this.miStPadre.padre) return null;
      return this.$store.state[this.miStPadre.padre];
    },
  },

  watch: {
    miStHijo() {
      console.log('HIJO WATCH::: ', this.miStHijo, this.stNameHijo);
      if (!this.miStHijo) return;
      if (this.miStHijo.name!= this.stNameHijo) {
        this.$root.$alert.open('Llame a informática al teléfono 666564931 indicando lo siguiente: HIJO= ' + this.miStHijo.name, 'info');
        //this.$store.commit('data2State', { modulo:this.stName, prop:'hijo', value:this.stNameHijo});
      }
    }
  }
};
</script>
<style scoped>
.v-input--selection-controls {
  margin-top: 0;
}
.select {
  /* outline: 1px solid red; */
  border: 1px solid rgb(94, 114, 134, 0.6);
  border-radius: 18px 18px 18px 18px;
  font: 1rem;
  width: 200px;
  height: 25px;
  outline: none;

  margin-right: 2px;
  margin-top: 4px;
  text-align-last: center;

  /* background-color: rgb(236, 182, 157); */
  /* background-color: rgb(10, 173, 179); */
  background-image: none;
  cursor: pointer;
  /* background: url(assets/selectArrow.png) no-repeat right #BADA55; */
  /* clip-path: polygon(100% 0%, 0 0%, 50% 100%); */
}
</style>
